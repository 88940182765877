import * as qs from 'qs';
import firebase from "../../firebase";
import { useLocation } from "react-router-dom";
import { TextField, Button, Snackbar, CircularProgress } from "@material-ui/core"; // Import Snackbar and CircularProgress from @material-ui/core
import { useRef, useState } from 'react';
import SendIcon from '@material-ui/icons/Send';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import config from '../../config'; // Import the config file
import "./initiateEmailLinkSignIn.css";


const InitiateEmailLinkSignIn = () => {
    const location = useLocation();
    const parsedQs = qs.parse(location.search, { ignoreQueryPrefix: true });

    const redirect = parsedQs.redirect || "extension";

    const inputRef = useRef(null);
    const [emailError, setEmailError] = useState(false);
    const [sendError, setSendError] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(""); // Add state to store the email

    const sendEmailLink = () => {
        const emailInput = inputRef.current.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(emailInput)) {
            setEmailError(true);
            return;
        }

        setEmailError(false);
        setLoading(true); // Set loading to true
        // Send email link with firebase
        firebase.auth().sendSignInLinkToEmail(emailInput, {
            url: `${config.domains.siteUrl}/signin/complete/emaillink?redirect=${redirect}`, // Use the domain from config
            handleCodeInApp: true,
        }).then(() => {
            console.log("Email sent");
            window.localStorage.setItem('emailForSignIn', emailInput); // Store the email in local storage
            setEmail(emailInput); // Store the email in state
            setSendError(""); // Clear any previous errors
            setEmailSent(true); // Set emailSent to true
        }).catch((error) => {
            console.log(error);
            setSendError("Failed to send email. Please try again.");
        }).finally(() => {
            setLoading(false); // Set loading to false
        });
    }

    if (emailSent) {
        return (
            <div className='initiateEmailLinkSignInWrapper'>
                <CheckCircleIcon style={{ fontSize: 50, color: 'var(--themeColour)', animation: 'bounce 2s infinite' }} />
                <h2>Email Sent</h2>
                <p>Please check your email <strong>{email}</strong> and click the link to finish signing in.</p>
                <p style={{marginTop: 20}}>Make sure to open the link in Chrome <span className='whichever'>(or whichever browser you installed Selectext).</span></p>
            </div>
        );
    }

    return (
        <div className='initiateEmailLinkSignInWrapper'>
            <h2>Please enter your email address</h2>
            <p className='youWillBeSentText'>You will be sent a link to your email which will complete the sign in process.</p>
            <Snackbar
                open={!!sendError}
                autoHideDuration={6000}
                onClose={() => setSendError("")}
                message={sendError}
            />
            <div className='emailLinkInputWrappers'>
                <TextField 
                    variant='standard' 
                    label='Email' 
                    inputRef={inputRef} 
                    error={emailError}
                    helperText={emailError ? "Please enter a valid email address." : ""}
                    name='email'
                    autoComplete='email'
                />
                <Button
                    endIcon={loading ? <CircularProgress size={24} /> : <SendIcon />}
                    onClick={sendEmailLink}
                    className='donateButton sendEmailLinkButton'
                    disabled={loading} // Disable button while loading
                >
                    {loading ? "Sending..." : "Send Email Link"}
                </Button>
            </div>
        </div>
    );
}

export default InitiateEmailLinkSignIn;